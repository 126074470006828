import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseString } from '../converters';
import { TextColumnType } from '../../monday-api/api';

export type TextColumnValue = {
  text: string | null;
  value: string | null;
};

const handler: ColumnHandler<TextColumnType, TextColumnValue> = {
  type: 'text',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['empty_string', 'text'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['empty_string', 'text'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'contains_text',
      compare_value_ids: ['text'],
    },
    {
      operator: 'not_contains_text',
      compare_value_ids: ['text'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);

    return {
      text,
      value,
    };
  },

  getFormulaValue: (engineValue) => (engineValue.value === null ? '' : engineValue.value),
};

export default handler;
