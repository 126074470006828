import _ from 'lodash';
import { Board, ColumnType } from './monday-api/api';
import { handlersByType } from './engine/column_handlers/index';

export type RuleDefinition = {
  operator: keyof typeof OPERATORS;
  compare_attributes_ids?: Array<keyof typeof COMPARE_ATTRIBUTES>;
  compare_value_ids: Array<keyof typeof COMPARE_VALUES>;
};

export type RuleDefinitions = RuleDefinition[];

export type Rule = {
  column_id: string;
  operator: keyof typeof OPERATORS;
  compare_attribute_id?: keyof typeof COMPARE_ATTRIBUTES;
  compare_value: Array<{
    id: keyof typeof COMPARE_VALUES;
    value: any;
  }>;
};

export type Rules = Rule[];

export function validateRule(board: Board, rule: Partial<Rule>) {
  const filterableFields = getFilterableFields(board);

  if (!rule.column_id) {
    return {
      property: 'column_id',
      error: 'missing column id',
    };
  }

  const filterableField = filterableFields.find((column) => column.id === rule.column_id);

  if (!filterableField) {
    return {
      property: 'column_id',
      error: `invalid column id "${rule.column_id}". maybe this column was deleted?`,
    };
  }

  let supportedRules: null | RuleDefinitions = null;

  if (filterableField.type === 'name') {
    supportedRules = itemNameRuleDefinitions;
  } else if (handlersByType[filterableField.type] && handlersByType[filterableField.type].supported_rules) {
    supportedRules = handlersByType[filterableField.type].supported_rules!;
  }

  if (!supportedRules) {
    return {
      property: 'column_id',
      error: 'unsupported column type',
    };
  }

  if (!rule.operator) {
    return {
      property: 'operator',
      error: 'missing operator',
    };
  }

  if (!OPERATORS[rule.operator]) {
    return {
      property: 'operator',
      error: 'invalid operator',
    };
  }

  const ruleWithMatchingOperator = supportedRules.find((supportedRule) => supportedRule.operator === rule.operator);

  if (!ruleWithMatchingOperator) {
    return {
      property: 'operator',
      error: 'unsupported operator',
    };
  }

  if (rule.compare_attribute_id) {
    if (!ruleWithMatchingOperator.compare_attributes_ids) {
      return {
        property: 'compare_attribute_id',
        error: 'unsupported compare attribute',
      };
    }

    if (!ruleWithMatchingOperator.compare_attributes_ids.includes(rule.compare_attribute_id)) {
      return {
        property: 'compare_attribute_id',
        error: 'invalid compare attribute',
      };
    }
  }

  if (!rule.compare_value || !rule.compare_value.length) {
    return {
      property: 'compare_value',
      error: 'missing compoare value',
    };
  }

  if (rule.compare_value.length !== 1) {
    // for now, only support one compare value
    return {
      property: 'compare_value',
      error: 'too many compare values',
    };
  }

  const compareValue = rule.compare_value[0];
  const compareValueDefinition = COMPARE_VALUES[compareValue.id];

  if (!compareValueDefinition) {
    return {
      property: 'compare_value',
      error: 'compare value definition not found',
    };
  }

  if (
    'validate' in compareValueDefinition &&
    _.isFunction(compareValueDefinition.validate) &&
    !compareValueDefinition.validate(compareValue.value)
  ) {
    return {
      property: 'compare_value',
      error: 'invalid compare value',
    };
  }

  return null;
}

const itemNameRuleDefinitions: RuleDefinitions = [
  {
    operator: 'any_of',
    compare_value_ids: ['text'],
  },
  {
    operator: 'not_any_of',
    compare_value_ids: ['text'],
  },
  {
    operator: 'contains_text',
    compare_value_ids: ['text'],
  },
  {
    operator: 'not_contains_text',
    compare_value_ids: ['text'],
  },
];

export type FilterableField = {
  id: string;
  title: string;
  type: ColumnType | 'name';
  supported_rules: RuleDefinitions;
};

export function getFilterableFields(board: Board) {
  const fields: FilterableField[] = board.columns
    .filter((column) => {
      const handler = handlersByType[column.type];
      return handler && handler.supported_rules;
    })
    .flatMap((column) => {
      const handler = handlersByType[column.type];

      if (handler && handler.supported_rules) {
        return [
          {
            id: column.id,
            title: column.title,
            type: column.type,
            supported_rules: handler.supported_rules,
          },
        ];
      }

      return [];
    });

  fields.unshift({
    id: 'name',
    title: 'Item Name',
    type: 'name',
    supported_rules: itemNameRuleDefinitions,
  });

  return fields;
}

export function getSortableFields(board: Board) {
  const sortableFields = board.columns.map((column) => {
    return {
      id: column.id,
      title: column.title,
    };
  });

  sortableFields.unshift({
    id: '__creation_log__',
    title: 'Creation Date',
  });

  sortableFields.unshift({
    id: '__last_updated__',
    title: 'Last Updated',
  });

  return sortableFields;
}

export function getValidAndInvalidRules(rules: Rules, board: Board) {
  const invalidRulesBoolean = rules.map((rule) => !!validateRule(board, rule));

  const invalidRules = rules.filter((_rule, i) => invalidRulesBoolean[i]);
  const validRules = rules.filter((_rule, i) => !invalidRulesBoolean[i]);

  return {
    invalidRules,
    validRules,
  };
}

function escapeString(value: string) {
  return value.replace(/\\/g, '').replace(/"/g, '\\"');
}

function defaultCompareValueTransform(value: any) {
  // replace " with \"
  // TODO: test this...
  const escapedValue = _.isString(value) ? escapeString(value) : value;
  return `[${_.isString(value) ? `"${escapedValue}"` : value}]`;
}

function transformRuleToGraphQL(rule: Rule): string {
  const compareValueDefinition = COMPARE_VALUES[rule.compare_value[0].id];
  const transform = 'transform' in compareValueDefinition ? compareValueDefinition.transform : defaultCompareValueTransform;
  const transformedValue = transform('value' in compareValueDefinition ? compareValueDefinition.value : rule.compare_value[0].value);

  // TODO: this needs to be better tested... avoid the graphql breaking

  const pieces = [`column_id: "${rule.column_id}"`, `operator: ${rule.operator}`, `compare_value: ${transformedValue}`];

  return `{${pieces.join(', ')}}`;
}

export type BoardFilters = {
  rules?: Rules;
  order_by_column_id?: string;
  order_by_direction?: 'asc' | 'desc';
  operator?: 'and' | 'or';
};

export function transformBoardFiltersToGraphQL(boardFilters: BoardFilters) {
  const pieces: string[] = [];

  if (boardFilters.rules && boardFilters.rules.length) {
    const rulesGraphql = boardFilters.rules.map((rule) => transformRuleToGraphQL(rule));
    pieces.push(`rules: [${rulesGraphql.join(', ')}]`);
  }

  if (boardFilters.operator) {
    pieces.push(`operator:${boardFilters.operator}`);
  }

  if (boardFilters.order_by_column_id) {
    pieces.push(
      `order_by: {column_id: "${boardFilters.order_by_column_id}", direction: ${boardFilters.order_by_direction === 'desc' ? 'desc' : 'asc'}}`,
    );
  }

  if (!pieces.length) {
    return '';
  }

  return `{${pieces.join(', ')}}`;
}

export function getNormalizedBoardFilters(board: Board, boardFilters: BoardFilters | undefined) {
  if (!boardFilters) {
    return undefined;
  }

  const newBoardFilters = { ...boardFilters };
  const sortableFields = getSortableFields(board);

  if (boardFilters.order_by_column_id) {
    const sortableField = sortableFields.find((field) => field.id === boardFilters.order_by_column_id);

    if (!sortableField) {
      delete newBoardFilters.order_by_column_id;
      delete newBoardFilters.order_by_direction;
    }
  }

  if (!newBoardFilters.order_by_column_id) {
    delete newBoardFilters.order_by_direction;
  }

  if (!boardFilters.rules || !boardFilters.rules.length) {
    delete newBoardFilters.operator;
  }

  if ((!boardFilters.rules || !boardFilters.rules.length) && !boardFilters.order_by_column_id) {
    return undefined;
  }

  return newBoardFilters;
}

export function getNormalizedBoardFiltersGraphQL(board: Board, boardFilters: BoardFilters | undefined) {
  const normalizedFilters = getNormalizedBoardFilters(board, boardFilters);

  if (!normalizedFilters) {
    return undefined;
  }

  return transformBoardFiltersToGraphQL(normalizedFilters);
}

export function hasBoardFilters(board: Board, boardFilters: BoardFilters | undefined) {
  return !!getNormalizedBoardFiltersGraphQL(board, boardFilters);
}

export function areBoardFiltersValid(board: Board, boardFilters: BoardFilters | undefined) {
  if (!boardFilters) {
    return true;
  }

  if (boardFilters.rules) {
    for (const rule of boardFilters.rules) {
      const validationError = validateRule(board, rule);

      if (validationError) {
        return false;
      }
    }
  }

  return true;
}

function isValidDate(val: any) {
  return _.isString(val) && val.match(/^\d{4}-\d{2}-\d{2}$/) !== null && new Date(val).toString() !== 'Invalid Date';
}

export const OPERATORS = {
  is_empty: {
    label: 'is empty',
  },
  is_not_empty: {
    label: 'is not empty',
  },
  any_of: {
    label: 'any of',
  },
  not_any_of: {
    label: 'not any of',
  },
  contains_text: {
    label: 'contains text',
  },
  not_contains_text: {
    label: 'not contains text',
  },
  greater_than: {
    label: 'greater than',
  },
  greater_than_or_equals: {
    label: 'greater than or equals',
  },
  lower_than: {
    label: 'lower than',
  },
  // used in date columns:
  lower_than_or_equal: {
    label: 'lower than or equals',
  },
  // used in number columns:
  lower_than_or_equals: {
    label: 'lower than or equals',
  },
  starts_with: {
    label: 'starts with',
  },
  ends_with: {
    label: 'ends with',
  },
  equals: {
    label: 'equals',
  },
  between: {
    label: 'between',
  },
};

export const COMPARE_VALUES = {
  empty_null: {
    label: 'empty',
    value: null,
  },
  empty_string: {
    label: 'blank',
    value: '',
  },
  text: {
    label: 'text',
    type: 'string',
  },
  item_id: {
    label: 'item id',
    type: 'number',
    parse: (value: any) => (_.isString(value) ? +value : value),
    validate: (val: any) => _.isNumber(val),
  },
  number: {
    label: 'number',
    type: 'number',
    parse: (value: any) => (_.isString(value) ? +value : value),
    validate: (val: any) => _.isNumber(val),
  },
  country_short: {
    label: 'country (two letters)',
    type: 'string',
    validate: (val: any) => _.isString(val) && val.length === 2,
  },
  user_id: {
    label: 'user id',
    type: 'number',
    parse: (value: any) => (_.isString(value) ? +value : value),
    validate: (val: any) => _.isNumber(val),
  },
  user_id_string: {
    label: 'user id',
    type: 'string',
    description: 'the user id as string (e.g. "person-123456")',
    //parse: (value) => +value,
    //validate: (val: any) => _.isNumber(val),
  },
  empty_user: {
    label: 'blank',
    value: 'person-0',
  },
  today: {
    label: 'today',
    value: 'TODAY',
    description: `Items with today's date.`,
  },
  tomorrow: {
    label: 'tomorrow',
    value: 'TOMORROW',
    description: `Items with tomorrow's date.`,
  },
  yesterday: {
    label: 'yesterday',
    value: 'YESTERDAY',
    description: `Items with yesterday's date.`,
  },
  this_week: {
    label: 'this week',
    value: 'THIS_WEEK',
    description: `Items with a date during the current week. The first day of the week (either Sunday or Monday) is defined based on the settings configured in the monday.com account of the person making the API call.`,
  },
  next_weeks: {
    label: 'next weeks',
    value: 'NEXT_WEEKS',
    description: `future weeks`,
  },
  past_weeks: {
    label: 'past weeks',
    value: 'PAST_WEEKS',
    description: `past weeks`,
  },
  one_week_ago: {
    label: 'one week ago',
    value: 'ONE_WEEK_AGO',
    description: `Items with a date during the previous week. The first day of the week (either Sunday or Monday) is defined based on the settings configured in the monday.com account of the person making the API call.`,
  },
  one_week_from_now: {
    label: 'one week from now',
    value: 'ONE_WEEK_FROM_NOW',
    description: `Items with a date during the upcoming week. The first day of the week (either Sunday or Monday) is defined based on the settings configured in the monday.com account of the person making the API call.`,
  },
  this_month: {
    label: 'this month',
    value: 'THIS_MONTH',
    description: `Items with a date during the current month.`,
  },
  one_month_ago: {
    label: 'one month ago',
    value: 'ONE_MONTH_AGO',
    description: `Items with a date during the previous month.`,
  },
  one_month_from_now: {
    label: 'one month from now',
    value: 'ONE_MONTH_FROM_NOW',
    description: `Items with a date during the upcoming month.`,
  },
  past_datetime: {
    label: 'past datetime',
    value: 'PAST_DATETIME',
    description: `Items with a date and time in the past.`,
  },
  future_datetime: {
    label: 'future datetime',
    value: 'FUTURE_DATETIME',
    description: `Items with a date and time in the future.`,
  },
  current: {
    label: 'current',
    value: 'CURRENT',
  },
  due_today: {
    label: 'due today',
    value: 'DUE_TODAY',
  },
  upcoming: {
    // TODO: Test how this works before showing it as an option
    label: 'upcoming',
    value: 'UPCOMING',
    description: `Used in conjunction with a status column, this compare value returns items that are not marked as "Done" and the date in the date column has not passed. Please note that the item will not show as upcoming if today's date is in the date column.`,
  },
  overdue: {
    // TODO: Test how this works before showing it as an option
    label: 'overdue',
    value: 'OVERDUE',
    description: `Used in conjunction with a status column, this compare value returns items that are not marked as "Done" and the date in the date column has passed. Please note that the item will not show as overdue until the following day if the date column contains today's date.`,
  },
  done_on_time: {
    // TODO: Test how this works before showing it as an option
    label: 'done on time',
    value: 'DONE_ON_TIME',
    description: `Used in conjunction with a status column, this compare value returns items that were marked as "Done" before the date in the date column.`,
  },
  done_overdue: {
    // TODO: Test how this works before showing it as an option
    label: 'done overdue',
    value: 'DONE_OVERDUE',
    description: `Used in conjunction with a status column, this compare value returns items that were marked as "Done" after the date in the date column.`,
  },
  exact_date: {
    label: 'exact date',
    type: 'string',
    validate: (val: any) => isValidDate(val),
    description: `Items with a specific date. The dates must be in "YYYY-MM-DD" format.`,
    transform: (val: string) => `["EXACT", "${escapeString(val)}"]`, // TODO: test this
  },
  between_dates: {
    label: 'between dates',
    type: 'string_pair',
    validate: (val: any) => Array.isArray(val) && isValidDate(val[0]) && isValidDate(val[1]),
    description: `Items with a date between two specific dates. The dates must be in "YYYY-MM-DD" format.`,
    transform: (val: [string, string]) => `["${escapeString(val[0])}", "${escapeString(val[1])}"]`, // TODO: test this
  },
  past_timeline: {
    label: 'past timeline',
    value: 'PAST_TIMELINE',
    description: `Items with a timeline that is in the past.`,
  },
  future_timeline: {
    label: 'future timeline',
    value: 'FUTURE_TIMELINE',
    description: `Items with a timeline that is in the future`,
  },
  milestone: {
    label: 'milestone',
    value: 'MILESTONE',
  },
  blank_value: {
    label: 'blank',
    value: '$$$blank$$$',
    description: `Items with a blank value.`,
  },
  label_id: {
    // TODO: test this
    label: 'label id',
    type: 'string',
    validate: (val: any) => _.isString(val),
  },
  early_morning: {
    label: 'early morning',
    value: 'Early morning',
    description: `Items with a time between 4:00 and 7:00 AM. Please note that these times are based on the monday.com timezone configuration of the user making the API call.`,
  },
  morning: {
    label: 'morning',
    value: 'Morning',
    description: `Items with a time between 7:00 and 12:00 PM. Please note that these times are based on the monday.com timezone configuration of the user making the API call.`,
  },
  afternoon: {
    label: 'afternoon',
    value: 'Afternoon',
    description: `Items with a time between 12:00 and 8:00 PM. Please note that these times are based on the monday.com timezone configuration of the user making the API call.`,
  },
  evening: {
    label: 'evening',
    value: 'Evening',
    description: `Items with a time between 8:00 and 11:00 PM. Please note that these times are based on the monday.com timezone configuration of the user making the API call.`,
  },
  night: {
    label: 'night',
    value: 'Night',
    description: `Items with a time between 11:00 pm and 4:00 am. Please note that these times are based on the monday.com timezone configuration of the user making the API call.`,
  },
  assigned_to_me: {
    label: 'assigned to me',
    value: 'assigned_to_me',
    description: `Items that are assigned to the user making the API call.`,
  },
  progress_0: {
    label: 'under 20%',
    value: 0,
    description: `Items less than 20% done`,
  },
  progress_20: {
    label: 'over 20%',
    value: '20',
    description: `Items more than 20% done`,
  },
  progress_50: {
    label: 'over 50%',
    value: '50',
    description: `Items more than 50% done`,
  },
  progress_80: {
    label: 'over 80%',
    value: '80',
    description: `Items more than 80% done`,
  },
  progress_100: {
    label: 'done',
    value: '100',
    description: `Items that are done`,
  },
  rating_0: {
    label: 'blank',
    value: 0,
  },
  rating_1: {
    label: 'rated 1',
    value: 1,
  },
  rating_2: {
    label: 'rated 2',
    value: 2,
  },
  rating_3: {
    label: 'rated 3',
    value: 3,
  },
  rating_4: {
    label: 'rated 4',
    value: 4,
  },
  rating_5: {
    label: 'rated 5',
    value: 5,
  },
  status: {
    // TODO: implement
    label: 'status',
    type: 'status',
    validate: (val: any) => _.isNumber(val),
  },
  status_blank: {
    label: 'blank',
    value: 5,
  },
  tag_blank: {
    label: 'blank',
    value: -1,
  },
  tag_id: {
    // TODO: make this more user friendly
    label: 'tag id',
    type: 'number',
    parse: (value: any) => (_.isString(value) ? +value : value),
    validate: (val: any) => _.isNumber(val),
  },
  time_tracking_paused: {
    label: 'paused',
    value: 1,
    description: 'time tracker paused or empty',
  },
  time_tracking_running: {
    label: 'paused',
    value: 2,
    description: 'time tracker is running',
  },
  votes_empty: {
    label: 'empty',
    value: 'No votes',
  },
  timezone: {
    label: 'timezone',
    type: 'string',
    validate: (val: any) => _.isString(val),
  },
};

export const COMPARE_ATTRIBUTES = {
  created_by: {
    label: 'Created by',
    vylue: 'CREATED_BY',
  },
  start_date: {
    label: 'Start date',
    value: 'START_DATE',
  },
  end_date: {
    label: 'End date',
    value: 'END_DATE',
  },
};
