import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseNumber, parseString } from '../converters';
import { DropdownColumnType } from '../../monday-api/api';

type DropdownColumnValue = {
  text: string | null;
  ids: number[] | null;
};

const handler: ColumnHandler<DropdownColumnType, DropdownColumnValue> = {
  type: 'dropdown',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['empty_string', 'label_id'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['empty_string', 'label_id'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'contains_text',
      compare_value_ids: ['text'],
    },
    {
      operator: 'not_contains_text',
      compare_value_ids: ['text'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    let ids: number[] | null = null;

    if (Array.isArray(_.get(value, 'ids'))) {
      ids = ((_.get(value, 'ids') || []) as Array<number>).map((id) => parseNumber(id)).filter((id) => id !== null) as number[];
    }

    return {
      text,
      ids,
    };
  },

  getFormulaValue: (engineValue, modifier) => {
    if (modifier === 'Count') {
      return engineValue.ids?.length || 0;
    } else {
      return engineValue.text === null ? '' : engineValue.text;
    }
  },
};

export default handler;
