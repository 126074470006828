import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseDatetime, parseString } from '../converters';
import { DateColumnType } from '../../monday-api/api';

export type DateColumnValue = {
  text: string | null;
  date: string | null;
  changed_at: string | null;
};

const handler: ColumnHandler<DateColumnType, DateColumnValue> = {
  type: 'date',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: [
        'blank_value',
        'exact_date',
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
        'past_datetime',
        'future_datetime',
        'upcoming',
        'overdue',
        'done_on_time',
        'done_overdue',
      ],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: [
        'blank_value',
        'exact_date',
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
        'past_datetime',
        'future_datetime',
        'upcoming',
        'overdue',
        'done_on_time',
        'done_overdue',
      ],
    },
    {
      operator: 'greater_than',
      compare_value_ids: [
        'exact_date',
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
      ],
    },
    {
      operator: 'greater_than_or_equals',
      compare_value_ids: [
        'exact_date',
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
      ],
    },
    {
      operator: 'lower_than',
      compare_value_ids: [
        'exact_date',
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
      ],
    },
    {
      operator: 'lower_than_or_equal',
      compare_value_ids: [
        'exact_date',
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
      ],
    },
    {
      operator: 'between',
      compare_value_ids: ['between_dates'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const date = parseString(_.get(value, 'date'));
    const changedAt = parseDatetime(_.get(value, 'changed_at'));

    return {
      text,
      date,
      changed_at: changedAt,
    };
  },

  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
