import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseNumber, parseDatetime, parseString } from '../converters';
import { StatusColumnType } from '../../monday-api/api';

export type StatusColumnValue = {
  text: string | null;
  index: number | null;
  changed_at: string | null;
};

export type StatusColumnSettings = {
  labels: Record<string, string>;
};

const handler: ColumnHandler<StatusColumnType, StatusColumnValue, StatusColumnSettings> = {
  type: 'status',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['status', 'status_blank'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['status', 'status_blank'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const index = parseNumber(_.get(value, 'index'));
    const changedAt = parseDatetime(_.get(value, 'changed_at'));

    return {
      text,
      index,
      changed_at: changedAt,
    };
  },

  convertSettingsToEngine: (settings: string) => {
    return parseJson(settings) as StatusColumnSettings;
  },

  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
