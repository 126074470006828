import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseNumber, parseDatetime, parseString } from '../converters';
import { RatingColumnType } from '../../monday-api/api';

export type RatingColumnValue = {
  text: string | null;
  rating: number | null;
  changed_at: string | null;
};

const handler: ColumnHandler<RatingColumnType, RatingColumnValue> = {
  type: 'rating',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['rating_0', 'rating_1', 'rating_2', 'rating_3', 'rating_4', 'rating_5'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['rating_0', 'rating_1', 'rating_2', 'rating_3', 'rating_4', 'rating_5'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const rating = parseNumber(_.get(value, 'rating'));
    const changedAt = parseDatetime(_.get(value, 'changed_at'));

    return {
      text,
      rating,
      changed_at: changedAt,
    };
  },

  getFormulaValue: (engineValue) => (engineValue.rating === null ? 0 : engineValue.rating),
};

export default handler;
